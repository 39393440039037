<template>
  <div class="Information">
    <div v-loading="loading"></div>
    <div>
      <el-form :model="form" ref="Forms" :rules="rule" label-width="80px" class="InfoForm flex">
        <div class="Infos Info1">
          <!-- <el-form-item label="昵称" prop="name">
            <div class="co-black" v-if="readonlys">{{form.name}}</div>
            <el-input v-else v-model="form.name" placeholder="请输入您的昵称"></el-input>
          </el-form-item> -->
          <div class="flex2">
            <el-form-item label="姓氏" prop="surname">
              <div class="co-black wname" v-if="readonlys">{{form.surname}}</div>
              <el-input v-else v-model="form.surname" placeholder="请输入您的姓氏"></el-input>
            </el-form-item>
            <el-form-item label="名字" prop="username">
              <div class="co-black" v-if="readonlys">{{form.username}}</div>
              <el-input v-else v-model="form.username" placeholder="请输入您的名字"></el-input>
            </el-form-item>  
          </div>
          <el-form-item label="性别" prop="sex">
            <div class="co-black" v-if="readonlys">{{form.sex}}</div>
            <el-radio-group v-else v-model="form.sex">
              <el-radio label="先生"></el-radio>
              <el-radio label="女士"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <div class="co-black" v-if="readonlys">{{form.email}}</div>
            <el-input v-else v-model="form.email" placeholder="请输入您的邮箱"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <div class="co-black" v-if="readonlys">{{form.phone}}</div>
            <el-input v-else v-model="form.phone" @blur="phonechange" placeholder="请输入您的手机号"></el-input>
          </el-form-item>
          <el-form-item v-if="!readonlys" label="验证码" :prop="this.code1 == 1 ? 'code' : 'empty'">
            <div class="flex2">
              <el-input v-model="form.code" placeholder="请输入验证码"></el-input>
              <el-button v-if="codeShow" @click="getPhoneCode" class="getCode ml Iscode">获取验证码</el-button>
              <el-button class="getCode ml" v-else disabled>{{ count }} 秒后重试</el-button>
            </div>
          </el-form-item>
          <el-form-item label="剑盟码">
            <div class="invitationCode">{{form.invitationCode}}</div>
          </el-form-item>
        </div>
        <div class="Infos Info2">
          <!-- <el-form-item v-if="Isposition" label="工作职能" prop="positionId">
            <div class="co-black" v-if="readonlys">{{positionLabel}}</div>
            <el-select v-else v-model="form.positionId" placeholder="请选择您的工作职能">
              <el-option v-for="item in SelectModule.position" :label="item.text" :value="item.value" :key="item.value"></el-option>
            </el-select>
          </el-form-item> -->
          <!-- 公司版本1 -->
          <!-- <el-form-item label="公司">
            <div class="co-black" v-if="readonlys">{{form.company}}</div>
            <el-input v-else v-model="form.company" placeholder="请输入您的公司" :disabled="disabled"></el-input>
          </el-form-item> -->
          <!-- 公司版本2 -->
          <el-form-item label="公司" prop="company">
            <div class="co-black" v-if="readonlys">{{form.company}}</div>
            <el-select v-else v-model="form.company" placeholder="请输入公司" filterable remote reserve-keyword :remote-method="remoteMethod" :loading="loading">
              <el-option v-for="(item, index) in searchOptions" :key="index" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="昵称" prop="name">
            <div class="co-black" v-if="readonlys">{{form.name}}</div>
            <el-input v-else v-model="form.name" placeholder="请输入您的昵称"></el-input>
          </el-form-item>
          <!-- 行业版本1 -->
          <!-- <el-form-item v-if="Isposition" label="行业" prop="industry">
            <div class="co-black" v-if="readonlys">{{industryLabel.join('、')}}</div>
            <el-select v-else v-model="form.industry" placeholder="请选择您的行业">
              <el-option v-for="item in SelectModule.industrys" :label="item.text" :value="item.value" :key="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-else label="行业" prop="industry">
            <div class="co-black" v-if="readonlys">{{industryLabel.join('、')}}</div>
            <el-select v-else v-model="form.industry" multiple placeholder="请选择您的行业(可多选)">
              <el-option v-for="item in SelectModule.industrys" :label="item.text" :value="item.value" :key="item.value"></el-option>
            </el-select>
          </el-form-item> -->
          <!-- 行业版本2 -->
           <el-form-item label="行业" prop="industrySecond" multiple>
            <div class="co-black max-black" v-if="readonlys">{{industryLabel.join('、')}}</div>
            <el-cascader
                v-else
                v-model="IndustryItems"
                :options="SelectModule.industrys"
                placeholder="请选择行业(可多选)"
                :props="props"
                collapse-tags
              @change="IndustryItem1"></el-cascader>
          </el-form-item>
          <el-form-item label="其他行业" prop="industryOther" v-if="IsIndustryOther">
            <div class="co-black" v-if="readonlys">{{form.industryOther}}</div>
            <el-input v-else v-model.trim="form.industryOther" placeholder="请输入行业"></el-input>
          </el-form-item>
          <!-- <el-form-item label="职务" prop="scope">
            <div class="co-black" v-if="readonlys">{{ scopeLabel }}</div>
            <el-select v-else v-model="form.scope" placeholder="请选择您的职务">
              <el-option v-for="item in SelectModule.scope" :label="item.text" :value="item.value" :key="item.value"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="职务" prop="scope">
            <div class="co-black" v-if="readonlys">{{ form.scopeOther ? form.scopeOther : scopeLabel }}</div>
              <el-cascader
                v-else
                v-model="ScodeItems"
                :options="SelectModule.position"
                placeholder="请选择职务"
                :props="props1"
                @change="ScodeItem1"></el-cascader>
              <el-form-item prop="scopeOther" v-if="IsOther && !readonlys" style="margin-top: 10px;">
                <el-input v-model.trim="form.scopeOther" placeholder="请输入职务"></el-input>
              </el-form-item>
          </el-form-item>
        </div>
      </el-form>
      <div class="tc">
        <el-button v-if="readonlys" type="info" size="medium" @click="readonlys = false" class="editInfo">我要修改</el-button>
        <el-button v-if="!readonlys"  :loading="loading1" type="info" size="medium" @click="InfoSubmit('Forms')" class="editInfo">保存</el-button>
        <el-button v-if="!readonlys" size="medium" @click="readonlys = !readonlys" class="editInfo">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as profile from "@/api/profile.js";
import { getCode } from "@/api/register.js";
import { GetCompanyList } from "@/api/Home.js";
import { dataState } from "@/api/all.js";
import { GetWxShares } from "@/utils/common";


export default {
  name: "Information",
  data() {
    return {
      props: { multiple: true, value: 'value', label: 'text', children: 'list' },
      props1: { multiple: false, value: 'value', label: 'text', children: 'list' },
      searchOptions: [],    // !搜索公司选项
      IndustryItems:'',     // !行业多选
      ScodeItems: '',       // !职务单选

      loading: true,        // !loading效果
      loading1: false,      // !修改信息按钮
      Isposition: false,    // !是否展示工作职能
      SelectModule: this.$store.state.Module, // !行业、工作职能、职务
      Info:this.$store.state.Infos,           // !个人信息
      industryLabel: [],    // !行业label
      positionLabel: [],    // !工作职能label
      scopeLabel:[],        // !职务label
      disabled: true,       // !是否修改状态
      readonlys: true,      // !是否只读状态
      phone1: "",           // !获取的手机号
      code1: 0,             // !动态验证
      IsIndustryOther: false, // !行业选择其他
      IsOther: false,       // !职务其他
      form: {
        surname: "",        // !姓
        username: "",       // !名
        name: "",           // !姓名
        sex: "",            // !性别
        email: "",          // !邮箱
        phone: "",          // !手机号
        code: "",           // !验证码
        positionId: "",     // !工作职能
        company: "",        // !公司
        industry: "",       // !行业
        industrySecond: "", // !行业二级
        industryOther: "",  // !其他行业
        scope: "",          // !工作职能二级
        scopeOther: ""      // !其他
      },
      copyPhone:'',         // !获取验证码验证手机号是否改变
      rule: {
        surname: [
          { required: true, message: "请输入您的姓氏", trigger: "blur" },
          { min: 1, max: 30, message: "长度在1个字符至20个字符之间", trigger: "blur"},
        ],
        username: [
          { required: true, message: "请输入您的名字", trigger: "blur" },
          { min: 1, max: 30, message: "长度在1个字符至20个字符之间", trigger: "blur"},
        ],
        name: [{ required: true, message: "请输入您的昵称", trigger: "blur" },{ min: 2, max: 30, message: "长度在2个字符至30个字符之间", trigger: "blur"},],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        email: [
          { required: true, message: "请输入您的邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        company: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        industrySecond: [{ required: true, message: "请选择行业", trigger: "blur" }],
        industryOther: [{ required: true, message: "请输入行业", trigger: "blur" }],
        positionId: [
          { required: true, message: "请选择工作职能", trigger: "blur" },
        ],
        scope: [{ required: true, message: "请选择职务", trigger: "blur" }],
        scopeOther: [{ required: true, message: "请输入职务", trigger: "blur" }]
      },
      codeShow: true,
      count: 0,
    };
  },
  mounted() {
    const share = {
      title: '个人中心 | 剑盟社区',
      desc: ''
    }
    GetWxShares(share);
    if(!this.$store.state.token) {
      this.$router.push('/');
    }else {
      this.GetInit();
      // 数据统计
      const data ={
        "visitType": 1,
        "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
        "url": window.location.href,
        "pageUrl": "Information",
        "relationId": ""
      }
      dataState(data).then(res => {
        sessionStorage.setItem('gid',res.data);
      })
    }
  },
  methods: {
    GetInit() {
      if(this.SelectModule.industrys.length > 0 && this.SelectModule.position.length > 0){
        // !电话
        console.log(typeof this.Info.partnerType)
        this.phone1 = this.Info.phone;
        // !行业
        var industry = this.Info.industrySecond ? this.Info.industrySecond.split(',') : []
        var IndustryList = []
        var IndustryName = []
        this.SelectModule.industrys.filter(item => {
          item.list.filter(val => {
            if(industry.indexOf(val.value) > -1){
              IndustryList.push([item.value, val.value])
              IndustryName.push(item.text + '/' + val.text)
            }
          })
        })
        this.IndustryItems = IndustryList
        this.industryLabel = IndustryName

        // 职务
        this.ScodeItems = [this.Info.positionId + "", this.Info.scope + ""]
        var scopeName
        var otherName
        this.SelectModule.position.filter(item => {
          item.list.filter(val => {
            if(val.value == this.Info.scope){
              scopeName = item.text + '/' + val.text
              otherName = val.text
            }
          })
        })
        this.scopeLabel = scopeName
        if (this.Info.scopeOther || otherName == '其他') {
          this.IsOther = true
        }

        if(this.Info.industryOther){
          this.IsIndustryOther = true
        }

        // this.SelectModule.industrys.filter(item1 => {
        //   this.Info.industry.split(",").filter(item2 =>{
        //     if(item1.value == item2){
        //       this.industryLabel.push(item1.text);
        //     }
        //   })
        // });
        // if(!this.Info.isPartner){
        //   this.Isposition = true;
        //   this.Info.industry = this.Info.industry.split(",")[0];
        // }
        // !职务
        // const scopes = this.SelectModule.scope.filter(v => v.value * 1 == this.Info.scope)[0];
        // this.scopeLabel = scopes.text;

        // // !工作职能
        // let positions = {};
        // if(!this.Info.isPartner && this.Info.positionId) {
        //   positions = this.SelectModule.position.filter(v => v.value * 1 == this.Info.positionId)[0];
        //   this.positionLabel = positions.text;
        // }else {
        //   positions = { value: '', text: '' }
        //   this.positionLabel = positions.text;
        // }
        this.form = {
          surname: this.Info.surName,
          username: this.Info.userName,
          name: this.Info.name,
          sex: this.Info.sex ? '先生' : '女士',
          email: this.Info.email,
          phone: this.Info.phone,
          code: "",
          positionId: this.Info.positionId,
          company: this.Info.company,
          industry: this.Info.industry,      
          industrySecond: this.Info.industrySecond, 
          industryOther: this.Info.industryOther, 
          // industry: this.Info.isPartner ? this.Info.industry.split(',') : this.Info.industry,
          scope: this.Info.scope,
          invitationCode: this.Info.invitationCode,
          scopeOther: this.Info.scopeOther ? this.Info.scopeOther : ''
        }
      } 
      this.loading = false
    },
    // 行业选择
    IndustryItem1(value){
      console.log(value)
      var arr = []
      var arr_children = []
      this.IndustryItems.forEach(item => {
        arr.push(item[0])
        arr_children.push(item[1])
      })

      // 判断选择其他时
      let textArr = []
      this.$store.state.Module.industrys.forEach(item => {
        if(arr.includes(item.value)){
          textArr.push(item.text)
        }
      })
      if(textArr.includes('其他')){
        this.IsIndustryOther = true
      }else{
        this.IsIndustryOther = false
        this.form.industryOther = ""
      }

      this.form.industry = [...new Set(arr)].join(',')
      this.form.industrySecond = arr_children.join(',')
    },
    // 职务选择
    ScodeItem1() {
      this.form.positionId = this.ScodeItems[0]
      this.form.scope = this.ScodeItems[1]
      var str
      this.$store.state.Module.position.filter(item => {
        item.list.filter(val => {
          if(val.value == this.ScodeItems[1]){
            str = val.text
          }
        })
      })
      if(str == '其他') {
        this.IsOther = true
      } else {
        this.IsOther = false
        this.form.scopeOther = ""
      }
    },
    // 搜索公司
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.GetCompanyList(query);
        }, 200);
      } else {

      }
    },
    // 获取公司列表
    GetCompanyList(key) {
      GetCompanyList({keyword: key}).then(res => {
        this.searchOptions = res.data
      })
    },
    // !获取手机验证码
    getPhoneCode() {
      const phone = this.form.phone;
      if(phone == this.phone1){
        this.$toast({ message: "手机号未发生变化，无需验证码操作！" });
        return false;
      } else if (!phone) {
        this.$toast({ message: "请先输入您的手机号！" });
        return false;
      } else if (!validator(phone)) {
        this.$toast({ message: "请输入正确的手机号！" });
        return false;
      } else {
        getCode({ phone: phone, type: 2 }).then((res) => {
          if (res.status == 1) {
            this.$toast({ message: "验证码发送成功!", duration: 1000 });
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.codeShow = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.codeShow = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.$toast({ message: res.message, duration: 2000 });
          }
        });
      }
      // 正则验证格式
      function validator(value) {
        var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        return RegExp(reg).test(value);
      }
    },
    // !监听手机号是否改变
    phonechange() {
      if (this.phone1 != this.form.phone) {
        this.code1 = 1;
      } else {
        this.code1 = 0;
        this.$refs.Forms.clearValidate();
      }
    },
    // !保存信息
    InfoSubmit(forms) {
      this.$refs[forms].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.loading1 = true;
          // // 选择类型 aveva员工验证
          // // var reg = /^([a-zA-Z0-9_.-])+@aveva.com$/;
          // var reg = /^\w+([-+.]\w+)*@aveva.com$/;
          // var flag = reg.test(this.form.email)
          // if(this.Info.partnerType == 10852 && !flag){
          //   this.$toast('请重新选择类型');
          //   this.loading = false
          //   this.loading1 = false
          //   return false
          // }
          const formcopy = JSON.parse(JSON.stringify(this.form))
          if (formcopy.sex == "先生") {
            formcopy.sex = true;
          } else {
            formcopy.sex = false;
          }
          formcopy.positionId = parseInt(formcopy.positionId)
          formcopy.scope = parseInt(formcopy.scope)
          // this.SelectModule.industrys.filter((item) => {
          //   if (item.text == formcopy.industry) {
          //     formcopy.industry = item.value * 1;
          //   }
          // });
          // if(!this.Info.isPartner){
          //   this.SelectModule.position.filter((item) => {
          //     if (item.text == formcopy.positionId) {
          //       formcopy.positionId = item.value;
          //     }
          //   });
          // }else{
          //   formcopy.industry = formcopy.industry.join(',')
          // }
          
          // formcopy.positionId = formcopy.positionId * 1;
          // formcopy.scope = formcopy.scope * 1;
          
          profile.UpUserInfo(formcopy).then((res) => {
            this.$toast(res.message);
            if (res.status == 1) {
              setTimeout(() => {
                location.reload();
              }, 1000);
            } else {
              this.loading = false;
              this.loading1 = false;
            }
          });
        }
      });
    },
  },
  computed: {
    SelectModules() {
      return this.$store.state.Module;
    },
    Infos() {
      return this.$store.state.Infos;
    }
  },
  watch: {
    Infos(val) {
      this.Info = val;
    },
    SelectModules(val) {
      this.SelectModule = val;
      this.GetInit();
    },
    
  },
};
</script>

<style lang="scss">
$mobil_base: 750;
@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}
.wname{
  width: 80px;
}
.el-form-item__error{
  min-width: 100px;
}
.Infos .el-form-item__label{
  text-align: left;
}
#app .Information .Iscode:active,
#app .Information .Iscode:focus {background: initial;color: #6b18a8;}
#app .editInfo {width: 136px;}
.max-black{
  max-height: 100px;
  overflow: auto;
}
.el-cascader-menu:nth-child(even) {
   .el-checkbox {
      width: 100%;
      height: 100%;
      z-index: 10;
      position: absolute;
   }
   .el-cascader-node__label{
      margin-left: 10px;
    }
    .el-scrollbar__bar {
      opacity: 0 !important;
    }
 }
 .el-scrollbar {
    .el-scrollbar__bar {
      opacity: 1 !important;
    }
  }
 
.Information {
  padding: 80px 40px;
  .InfoForm {width: 100%;justify-content: space-between;}
  .Infos {width: 46%;}
  .el-form-item,
  .el-radio__input.is-checked + .el-radio__label {color: #6b18a8;}
  .el-select {width: 100%;}
  .el-cascader{ width: 100%; }
}
@media (max-width: 750px) {
  .wname{
    width: vw(120);
  }
  .Information {padding: vw(40) vw(30);
    .InfoForm {display: block;}
    .Infos {width: 100%;}
    .el-form-item,.el-form-item__label {height: auto;line-height: vw(60);}
  }
}
</style>